<template>
    <div class="PLR_3 H_100vh bg_fff">
        <div class="flex_rowL PTB_4">
            <van-icon name="arrow-left" class="color_000" @click="$router.go(-1)" size=".45rem" />
        </div>
        <div class="P_2">
            <div class="textL PTB_2 color_000 fontS_17 font_weight_bold">技能名称</div>
            <input type="text" v-model="diy" placeholder="请输入技能名称" class="PTB_2 W_100 BB_1_D6D6D6">
        </div>
        <div class="P_2">
            <div class="textL PT_6">技能分类</div>
            <div class="textL PTB_2 color_000 fontS_17 font_weight_bold">
                <span v-if="type!='' ">{{type}}</span>
                <span v-if="type!='' " class="PLR_1">-></span>
                <span>{{name}}</span>
            </div>
        </div>
        <div @click="add" class="add">新增技能</div>
    </div>
</template>

<script>
    import '@/assets/css/common.css';
    import { Toast } from 'vant';

    export default {
        name: "",
        data() {
            return {
                title: '搜索',
                type:"",
                name:"",
                diy:"",
                positionId:""
            }
        },
        created() {
        },
        mounted() {
            let {type,name,positionId,level} = this.$route.query
            console.log(type,name,positionId,level)
            this.type = type
            this.name = name
            this.positionId = positionId
            this.level = level

        },
        methods: {
            add(){
                let {diy,  positionId,level} = this
                level = parseInt(level)+1
                console.log(positionId, diy,level)
                // if(diy == ""){
                //     Toast("请输入技能名称")
                //     return
                // }
                this.$http.post("/user/v1/Position/positionAdd",{reqType:"positionAdd",pid:positionId,position_name:diy,level:level})
                .then((res)=>{
                    let response = JSON.parse(res.data)
                    if(response.code == 0){
                        Toast.success(response.msg)
                    }else{
                        Toast.fail(response.msg)
                    }
                })
                .catch((e)=>{console.log(e)})

            }
        },
        components: {

        }
    }
</script>

<style scoped>
.add{
    width: 7.02rem;
    height: 0.97rem;
    background: linear-gradient(90deg, #FF4800 0%, #FC9E46 100%);
    border-radius: 0.16rem;
    text-align: center;
    line-height: 0.97rem;
    font-size: 0.3rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin-top:5rem;
}
</style>